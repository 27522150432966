import { useState, useEffect } from 'react'
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'

import { Profile } from 'types/Profile'
import { Portfolio } from 'types/Portfolio'
import { Item } from 'types/Item'
import { Collection } from 'types/Collection'

import Ripples from './Ripples'
import EditableTextBlock from 'components/utils/EditableTextBlock'
import WavedHeadline from 'components/utils/WavedHeadline'
import IconButton from 'components/utils/IconButton'
import HighlightItem from 'components/highlight/HighlightItem'
import { getItemType } from 'utils'

interface Props {
  profile: Profile
  portfolio?: Portfolio | null
  userItems: (Item | Collection)[]
  canEdit: boolean
  update: Function
}

const PortfolioAbout = ({ profile, portfolio, userItems, canEdit, update }: Props) => {
  const [loading, setLoading] = useState<any>({})

  const [ripples, setRipples] = useState<any[]>([])
  const [ripplesLayout, setRipplesLayout] = useState<string>('')

  const [isEditingRipples, setIsEditingRipples] = useState<boolean>(false)
  const [shouldPickRipple, setShouldPickRipple] = useState<number | null>(null) // index of the item to pick

  const possibleFirstName = profile?.full_name?.split(' ')[0]

  const fillRipples = (count) => (
    new Array(count)
      .fill(null)
      .map((_, i) => portfolio?.fetched_ripples?.[i] || {})
  )

  const parsePortfolio = () => {
    setRipples(fillRipples(8)) // fill ripples array with desired items count
    setRipplesLayout(portfolio?.ripples_layout || '')
  }

  useEffect(parsePortfolio, [portfolio])

  const updateData = async (attr, content) => {
    setLoading({ ...loading, [attr]: true })
    await update(attr, content)
    setLoading({ ...loading, [attr]: false })
  }

  const onRippleSelect = (e, item) => {
    e.preventDefault()
    setRipples(ripples.map((r, i) => i === shouldPickRipple ? item : r))
    setShouldPickRipple(null)
  }

  const saveRipples = async () => {
    const ripplesMap = ripples.map(it => (it.id ? { id: it.id, type: getItemType(it) } : {}))
    await updateData('ripples', ripplesMap)
    await updateData('ripples_layout', ripplesLayout)
    setIsEditingRipples(false)
  }

  return (
    <div className="w-max-1024">
      { (portfolio?.ripples?.length || canEdit) &&
        <section className="slide-fade-in">
          <WavedHeadline
            isEditing={ isEditingRipples }
            onEdit={ () => setIsEditingRipples(true) }
            onCancel={ () => {
              parsePortfolio()
              setIsEditingRipples(false)
            } }
            onSave={ saveRipples }
            editable={ canEdit }
          >
            { possibleFirstName }'s Ripples
          </WavedHeadline>
          { portfolio?.ripples.length || isEditingRipples
            ? <Ripples
                items={ ripples }
                layout={ ripplesLayout }
                editMode={ isEditingRipples }
                isLoading={ loading.ripples || loading.ripples_layout }
                onLayoutSelected={ (layout) => setRipplesLayout(layout) }
                onSelectItem={ (index) => setShouldPickRipple(index) }
              />
            : canEdit && 
              <div className="ripples-placeholder" onClick={ () => setIsEditingRipples(true) }>
                Click to highlight up to 8 of your Ocean Archive contributions.
              </div>
          }
        </section>
      }

      { (profile?.biography || canEdit) && (
        <section className="mt-5 pt-4 slide-fade-in">
          <EditableTextBlock
            title="Bio"
            content={ profile?.biography || '' }
            placeholder="Add bio"
            loading={ loading.biography }
            enabled={ canEdit }
            onSave={ c => updateData('biography', c) }
          />
        </section>
      ) }

      <div className="d-flex flex-column flex-xl-row">
        { (portfolio?.offering_text || canEdit) && (
          <section className="flex-even mt-5 pt-4 mr-xl-5 slide-fade-in">
            <EditableTextBlock
              title="What I can offer"
              content={ portfolio?.offering_text || '' }
              placeholder="Add something"
              loading={ loading.offering_text }
              enabled={ canEdit }
              onSave={ c => updateData('offering_text', c) }
            />
          </section>
        ) }

        { (portfolio?.looking_text || canEdit) && (
          <section className="flex-even mt-5 pt-4 slide-fade-in">
            <EditableTextBlock
              title="What I’m looking for"
              content={ portfolio?.looking_text || '' }
              placeholder="Add something"
              loading={ loading.looking_text }
              enabled={ canEdit }
              onSave={ c => updateData('looking_text', c) }
            />
          </section>
        ) }
      </div>

      <Modal isOpen={ shouldPickRipple !== null } className="large" modalClassName="pop">
        <ModalHeader close={ <IconButton icon="close" onClick={ () => setShouldPickRipple(null) } /> }>
          Select a Ripple
        </ModalHeader>
        <ModalBody>
          <Row className="profile-page__items">
            { userItems.map(it => (
              <Col xs="12" sm="6" md="4" className="px-1 mb-5" key={ it.id }>
                <HighlightItem data={ it as any } noTags onClick={ (e) => onRippleSelect(e, it) } />
              </Col>
            )) }
          </Row>
        </ModalBody>
      </Modal>

    </div>
  )
}

export default PortfolioAbout
