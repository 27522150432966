export const stringToHTML = function (str: string): HTMLElement {
  const parser = new DOMParser();
  const doc = parser.parseFromString(str, 'text/html');
  return doc.body;
};

// Source: https://stackoverflow.com/questions/10527983/best-way-to-detect-mac-os-x-or-windows-computers-with-javascript-or-jquery
export function isMacintosh() {
  return navigator.platform.indexOf('Mac') > -1;
}

export function isWindows() {
  return navigator.platform.indexOf('Win') > -1;
}

export function isWebsiteValid(website: string | null): boolean {
  try { 
    return Boolean(new URL(website as string))
  } catch (e){
    return false
  }
}

export function getSocialNetwork(url: string | null): string {
  if (!url) return ''
  if (url.includes('facebook.com')) return 'facebook'
  if (url.includes('instagram.com')) return 'instagram'
  if (url.includes('linkedin.com')) return 'linkedin'
  if (url.includes('twitter.com')) return 'twitter'
  if (url.includes('x.com')) return 'twitter'
  return ''
}

export const getItemType = (item: any) => {
  return item.type === 'post' ? 'story' : !!item.item_type ? 'item' : 'collection'
}

export const removeHTMLTags = (str: string) => {
  const helperEl = document.createElement('div')
  helperEl.innerHTML = str
  return helperEl.innerText
}

export const removeExtraNewlines = (text: string) => {
  return text.replace(/\n{3,}/g, '\n\n').replace(/\n*$/, '').replace(/^\n*/, '')
}

export const sanitizeInput = (str: string) => {
  return removeExtraNewlines(removeHTMLTags(str))
}
