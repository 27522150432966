import { FaMapMarkerAlt } from 'react-icons/fa'
import { Link } from 'react-router-dom'

import { Profile } from 'types/Profile'
import { isWebsiteValid } from 'utils'
import { WebIcon } from 'lib/socialIcons'

import Blob from 'components/blob/Blob'
import SocialLink from 'components/utils/SocialLink'
import defaultAvatar from 'images/defaults/avatar.png'

import './PortfolioHeader.scss'

interface Props {
  profile: Profile
  activeTab?: string
  onTabChange?: (tab: string) => void
}

const PortfolioHeader = ({ profile, activeTab = 'about', onTabChange }: Props) => {
  // const [ currentTab, onTabChange?. ] = useState('about')

  if (!profile) {
    return (
      <section className="portfolio-header" />
    )
  }

  const {
    full_name,
    position,
    affiliation,
    biography,
    website,
    field_expertise,
    profile_type,
    profile_image,
    social_media,
    city,
    country,
    aggregated_concept_tags,
    community_link
    // public_profile
  } = profile

  return (
    <section className="portfolio-header">
      <div className="portfolio-header__picture">
        {/* { profile_image && 
          <img src={ profile_image as string } />
        } */}
        {
          <Blob floating={ false } glow={ false } oneLine={ true } image={ profile_image || defaultAvatar } />
          // <Blob floating={ false } glow={ false } oneLine={ true } image={ defaultAvatar } />
        }
      </div>

      <div className="portfolio-header__details">
        <h1 className="portfolio-header__name">{ full_name }</h1>

        <p className="portfolio-header__subheadline">{ field_expertise ? field_expertise + ' | ' : ''}{ profile_type }</p>
        { (city || country) &&
          <p className="portfolio-header__data"><FaMapMarkerAlt size={14} /> { city + (country ? ', ' + country : '') }</p>
        }
        { position &&
          <p className="portfolio-header__data">{ position }</p>
        }
        { affiliation &&
          <p className="portfolio-header__data">Affiliation: { affiliation }</p>
        }

        <div className="portfolio-header__nav desktop-only">
          <button className={ 'button button--small' + (activeTab === 'about' ? ' active' : '') } onClick={ () => onTabChange?.('about') }>About</button>
          <button className={ 'button button--small' + (activeTab === 'content' ? ' active' : '') } onClick={ () => onTabChange?.('content') }>Content</button>
          <button className={ 'button button--small' + (activeTab === 'favourites' ? ' active' : '') } onClick={ () => onTabChange?.('favourites') }>My favourites</button>
        </div>

        { !!aggregated_concept_tags?.length && (
            <ul className="portfolio-header__tags">
              { aggregated_concept_tags.map((it, i) => (
                <li key={ i }>#{ it.tag_name }</li>
              ))}
            </ul>
        ) }

        <div className="portfolio-header__socials">
          { isWebsiteValid(website) && 
            <a href={ website || '' } target="_blank" rel="noopener noreferrer nofollow">
              <WebIcon />
            </a>
          }
          { social_media && social_media.map((it, i) => (
            <SocialLink url={ it } key={ i } />
          ))}
        </div>

        <div className="portfolio-header__actions">
          { isWebsiteValid(community_link || null) && (
            <div>
              <div className="caption">Contact me on the</div>
              <a href={community_link as string} target="_blank" className="button button--md-small">ocean comm/uni/ty</a>
            </div>
          )}

          <div>
            <div className="caption">Find</div>
            <Link to="/portfolios/search" className="button button--md-small">Similar portfolios</Link>
          </div>
        </div>
      </div>
    </section>
)
}

export default PortfolioHeader
