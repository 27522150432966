import { useEffect, useState, useRef } from 'react'
import { Spinner } from 'reactstrap'
import { Link } from 'react-router-dom'
import { FiEdit } from 'react-icons/fi'
import classNames from 'classnames'
import { itemURL, collectionURL } from 'urls'
import HtmlDescription from 'components/utils/HtmlDescription'
import Transition from 'components/utils/Transition'
import RipplesLayoutSvg from './RipplesLayoutSvg'

import { Ripples as WebGLRipples } from '@figliolia/ripples' // try curtains instead

import './Ripples.scss'

const layouts = [
  'one-only',
  'one-by-one',
  'one-by-two',
  'two-by-two',
  'one-by-four',
  'two-by-four',
  'four-by-four'
]

interface Props {
  items: any[]
  layout?: string | null
  editMode?: boolean
  isLoading?: boolean
  onLayoutSelected?: Function
  onSelectItem?: Function
}

const Ripples = ({ items, layout, editMode, isLoading, onLayoutSelected, onSelectItem }: Props) => {
  const _layout = layout || 'one-by-one'

  const [displayLayouts, setDisplayLayouts] = useState<string | boolean>(false)
  const contentRef = useRef(null)
  const imgOverlay = useRef(null)

  const leftItemsCount =
    _layout?.startsWith('one') ? 1
      : _layout?.startsWith('two') ? 2 : 4

  const rightItemsCount =
    _layout?.endsWith('one') ? 1
      : _layout?.endsWith('two') ? 2
        : _layout?.endsWith('only') ? 0 : 4

  const leftItems = items?.slice(0, leftItemsCount) || []
  const rightItems = items?.slice(leftItemsCount, leftItemsCount + rightItemsCount) || []

  const handleItemClick = (e, item) => {
    if (editMode) {
      e.preventDefault()
      onSelectItem?.(items.indexOf(item))
    }
  }

  useEffect(() => {
    setDisplayLayouts(editMode ? 'enter-from' : 'leave-to')
    editMode
      ? window.requestAnimationFrame(() => setDisplayLayouts(true))
      : setTimeout(() => setDisplayLayouts(false), 300)
  }, [editMode])

  const createRipples = () => {
    setTimeout(() => {
      const rips = new WebGLRipples(contentRef.current as any, {
        resolution: 512,
        dropRadius: 10,
        perturbance: 0.02,
      })
    }, 1000)
  }

  // useEffect(() => {
  //   if (contentRef.current) {
  //     createRipples()
  //   }
  // }, [items])

  const Item = ({ item }: { item: any }) => {
    const link = !!item.item_type ? itemURL(item.id) : collectionURL(item.id)

    return (
      <Link
        to={ link }
        className={ classNames('ripples__item', { empty: !item.id }) }
        onClick={ (e) => handleItemClick(e, item) }
      >
        <div className="ripples__item-image"><img src={ item?.file?.url } alt="" /></div>
        <div className="ripples__item-content">
          {/* <div className="ripples__item-actions">
          </div> */}
          <h3>{ item?.title }</h3>
          <div className="ripples__item-description">
            <HtmlDescription description={item?.description} />
          </div>
        </div>
        { editMode && (
          <div className="ripples__item-edit">
            <FiEdit size={ 28 } color="white" /> 
          </div>
        )}
      </Link>
    )
  }

  const EmptyItem = ({ item }: { item: any }) => {
    return (
      <div className="ripples__item empty" onClick={ (e) => handleItemClick(e, item) }>
        <div className="ripples__item-image" />
        { editMode && (
          <div className="ripples__item-edit">
            <FiEdit size={ 28 } color="white" /> 
          </div>
        )}
      </div>
    )
  }

  const classes = classNames(
    `ripples ripples--${_layout}`,
    { 'ripples--editing': editMode },
    { loading: isLoading}
  )

  return <>
    <div className={ classes }>
      <Transition show={ !!editMode }>
        <div className={ `ripples__layouts ${displayLayouts || ''}` }>
          { layouts.map((it) => (
            <div
              key={ it }
              className={ _layout === it ? 'active' : '' }
              onClick={ () => onLayoutSelected?.(it) }
              role="button"
            >
              <RipplesLayoutSvg name={ it } />
            </div>
          )) }
        </div>
      </Transition>

      <div ref={ contentRef } className={ classNames('ripples__content', { 'single-col': !rightItemsCount }) }>
        <div className={`ripples__left count-${leftItemsCount}`}>
          { leftItems?.map((it, i) => (
            it.id
              ? <Item item={ it } key={ i } />
              : <EmptyItem item={ it } key={ i } />
          )) }
        </div>
        <div className={`ripples__right count-${rightItemsCount}`}>
          { rightItems?.map((it, i) => (
            it.id
              ? <Item item={ it } key={ i } />
              : <EmptyItem item={ it } key={ i } />
          )) }
        </div>
      </div>

      { isLoading && <div className="loader"><Spinner /></div> }

      {/* <img ref={ imgOverlay } src="" className="ripples__overlay" alt="" /> */}
    </div>
  </>
}

export default Ripples
