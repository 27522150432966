import * as React from 'react'
import { connect } from 'react-redux'
import { API, a } from 'aws-amplify'
import { State as ProfileStoreState } from 'reducers/user/viewProfile'

import { RouteComponentProps, withRouter } from 'react-router'

import { Alerts, ErrorMessage } from '../../utils/alerts'
import { Profile } from '../../../types/Profile'
import { initializeProfileData } from 'actions/user/viewProfile'
import { addFileToItem } from 'actions/items/viewItem'
import { AuthContext } from 'providers/AuthProvider'

import { Item } from 'types/Item'
import { Collection } from 'types/Collection'
import { Portfolio } from 'types/Portfolio'

import './PortfolioDetail.scss'

import RollingHeader from 'components/layout/RollingHeader'
import PortfolioContent from './PortfolioContent'
import PortfolioHeader from './PortfolioHeader'
import PortfolioAbout from './PortfolioAbout'
import TabSwitch from 'components/utils/TabSwitch'

interface Props extends RouteComponentProps, Alerts {
  profile: Profile
  content: (Item | Collection)[]
  favourites: (Item | Collection)[]
  initializeProfileData: Function
  isItemsAndCollectionsLoading: boolean
  isFavouriteItemsAndCollectionsLoading: boolean
  isFavouriteItemsAndCollectionsLoaded: boolean
  fetchedAllItemsAndCollections: boolean
  isFavouriteStoriesLoading: boolean
}

interface State {
  errorMessage: string
  activeTab: string
  portfolio: Portfolio | null
  canEdit?: boolean
}

class PortfolioDetail extends React.Component<Props, State> {
  static contextType = AuthContext
  tabs = [
    { label: 'About', value: 'about' },
    { label: 'Content', value: 'content' },
    { label: 'Favourites', value: 'favourites' }
  ]
  profileId: string = ''
  canEdit: boolean = false

  constructor(props: any) {
    super(props)
    this.state = {
      errorMessage: '',
      activeTab: 'about',
      portfolio: null,
      canEdit: undefined
    }
  }

  componentDidMount() {
    const routeParams = this.props.match.params as any

    this.setState({ activeTab: routeParams.tab || 'about' })

    this.profileId = routeParams.profileId
    this.init()
  }

  componentDidUpdate(prevProps: any ) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.init()
    }

    this.canEdit = this.context.uuid === this.props.profile?.cognito_uuid
  }

  init = async (refresh?) => {
    if (!this.profileId) {
      this.setState({ errorMessage: 'Sorry, no profile found.' })
      return
    }
    const data = !!this.profileId && await Promise.all([
      API.get('tba21', 'portfolios', { queryStringParameters: { profileId: this.profileId } }),
      this.props.initializeProfileData(this.profileId, refresh)
    ])

    const portfolio = data[0]?.data

    if (portfolio) {
      const ripples = await this.fetchRipples(portfolio.ripples)
      portfolio.fetched_ripples = ripples
      this.setState({ portfolio })
    } else {
      this.setState({ errorMessage: 'Sorry, no portfolio found.' })
    }
  }

  update = async (attr: string, value: any) => {
    const path = ['biography'].includes(attr) ? 'profiles' : 'portfolios'
    const data = { body: { profileId: this.profileId, [attr]: value } }

    try {
      await API.patch('tba21', path, data)
      const result = await API.patch('tba21', path, data)
      if (!result.success) throw new Error()
    } catch {
      this.setState({ errorMessage: 'Sorry, something went wrong' })
    }
  }

  fetchRipples = async (list: any[]) => {
    if (!list?.length) return []

    const items = list.filter(it => it.type === 'item')
    const collections = list.filter(it => it.type === 'collection')

    const [ fetchedItems, fetchedCollections ] = await Promise.all([
      items.length && API.get('tba21', 'items/getMultiple', { queryStringParameters: { ids: items.map(it => it.id).join(',') } }),
      collections.length && API.get('tba21', 'collections/getMultiple', { queryStringParameters: { ids: collections.map(it => it.id).join(',') } })
    ])

    const itemsWithFile = await Promise.all( fetchedItems?.data?.map((it: any) => addFileToItem(it)) || [] )
    const all = [ ...itemsWithFile, ...fetchedCollections.data || [] ]

    return list.map(it => it.id
      ? all.find(ripple => Number(ripple.id) === Number(it.id))
      : it
    )
  }

  changeTab = (tab: string) => {
    this.setState({ activeTab: tab })
    window.history.replaceState(null, '', `/portfolios/${this.profileId}/${tab}`)
  }

  render() {
    const possibleFirstName = this.props.profile?.full_name?.split(' ')[0]
    const filteredData = this.props.content?.filter(it => !it.title?.startsWith('Banner')) || []

    return (
      <div className="background-rainbow">
        <ErrorMessage message={ this.state.errorMessage || this.props.errorMessage } sticky />
        <RollingHeader absolute noMargin />

        <div className="portfolio-detail">
          <PortfolioHeader profile={ this.props.profile } activeTab={ this.state.activeTab } onTabChange={ this.changeTab } />

          <div className="portfolio-detail__content theme-light">
            <div className="mobile-only mt-5 mb-5">
              <TabSwitch
                tabs={ this.tabs }
                activeTab={ this.state.activeTab }
                onTabChange={ this.changeTab }
              />
            </div>
            { this.state.activeTab === 'about' &&
              <PortfolioAbout
                profile={ this.props.profile }
                portfolio={ this.state.portfolio }
                userItems={ filteredData }
                update={ this.update }
                canEdit={ this.canEdit }
              />
            }
            { this.state.activeTab === 'content' &&
              <PortfolioContent data={ filteredData } title={ `${possibleFirstName}'s Content` } />
            }
            { this.state.activeTab === 'favourites' &&
              <PortfolioContent data={ this.props.favourites } title={ `${possibleFirstName}'s Starred` } />
            }
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: { viewProfile: ProfileStoreState }) => {
  return {
    errorMessage: state.viewProfile.errorMessage,
    profile: state.viewProfile.profile,
    content: [...state.viewProfile.items, ...state.viewProfile.collections],
    favourites: [...state.viewProfile.favouriteItems, ...state.viewProfile.favouriteCollections, ...state.viewProfile.favouriteStories],
    isFavouriteItemsAndCollectionsLoaded: state.viewProfile.isFavouriteItemsAndCollectionsLoaded,
    isItemsAndCollectionsLoading: state.viewProfile.isItemsAndCollectionsLoading,
    isFavouriteStoriesLoading: state.viewProfile.isFavouriteStoriesLoading,
    fetchedAllItemsAndCollections: !state.viewProfile.collectionsHasMore && !state.viewProfile.itemsHasMore
  }
}

export default withRouter(
  connect(mapStateToProps, { initializeProfileData })(PortfolioDetail)
)
