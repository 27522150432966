import { API } from 'aws-amplify'
import { SET_PORTFOLIOS, PORTFOLIOS_LOADING } from 'reducers/user/portfolios'

export const FETCH_PORTFOLIOS_ERROR = 'FETCH_PORTFOLIO_ERRORS'

export const fetchPortfolios = () => async dispatch => {
  dispatch({ type: PORTFOLIOS_LOADING, loading: true })

  try {
    const response = await API.get('tba21', 'portfolios', {})

    if (!response?.data) {
      dispatch({ type: FETCH_PORTFOLIOS_ERROR })
    } else {
      dispatch({ type: SET_PORTFOLIOS, portfolios: response?.data })
    }
  } catch (e) {
    dispatch({ type: FETCH_PORTFOLIOS_ERROR })
    console.log(e)
  } finally {
    dispatch({ type: PORTFOLIOS_LOADING, loading: false })
  }
}
